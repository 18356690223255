<template>
  <div class="digitalCommunity">
    <div class="PeopleTrend commonCss">
      <div class="title">
        <div class="title-l">01</div>
        <div class="title-c"></div>
        <div class="title-r">人员趋势</div>
      </div>
      <div id="PeopleTrend">
        <div class="item" v-for="(item, index) in PeopleTrendData" :key="index">
          <div class="item-c">{{ item.type }}</div>
          <div class="item-c">{{ item.total }}</div>
          <div class="item-c">{{ item.lastMonthCount }}</div>
          <div class="item-c">{{ item.thisMonthCount }}</div>
        </div>
      </div>
    </div>
    <div class="Dau commonCss">
      <div class="title">
        <div class="title-l">02</div>
        <div class="title-c"></div>
        <div class="title-r">每日日活</div>
      </div>
      <div id="Dau"></div>
    </div>
    <div class="ActPeople commonCss">
      <div class="title">
        <div class="title-l">03</div>
        <div class="title-c"></div>
        <div class="title-r">活跃应用（人数）</div>
      </div>
      <div id="ActPeople" v-if="actPeopleData.length != 0">
        <div class="item" v-for="(item, index) in actPeopleData" :key="index">
          <div class="item-l">
            <div class="num">{{ index + 1 }}</div>
            <div class="appName">{{ item.appName }}</div>
          </div>
          <div class="item-r">
            <div class="total">{{ item.total }}</div>
            <div class="img">
              <div class="line" :style="{ width: item.oWidth }"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="ActPeopleElse" v-else>暂无数据</div>
    </div>
    <div class="Points commonCss">
      <div class="title">
        <div class="title-l">04</div>
        <div class="title-c"></div>
        <div class="title-r">积分产生与核销</div>
      </div>
      <div id="Points">
        <div class="Points-t">
          <div class="Points-tl">
            <h3>累计产生积分</h3>
            <p>
              {{
                pointsData.pointProduceCount ? pointsData.pointProduceCount : 0
              }}分
            </p>
          </div>
          <div class="Points-tc"></div>
          <div class="Points-tr">
            <h3>累计核销积分</h3>
            <p>
              {{
                pointsData.pointConsumeCount ? pointsData.pointConsumeCount : 0
              }}分
            </p>
          </div>
        </div>
        <div id="Points-b"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPeopleTrendUrl,
  getDauUrl,
  getActPeopleUrl,
  getPointsUrl,
} from "./api.js";
import { drawLineChart, drawDLineChart } from "@/utils/echart";
import { mapState } from "vuex";
import { gloabalCount, toRegister } from "@/utils/common";
var moment = require("moment");
export default {
  name: "digitalCommunity",
  data() {
    return {
      currentMon: moment(new Date()).format("MM"),
      beforeMon: moment(new Date()).subtract(1, "month").format("MM"),
      myData: {},
      PeopleTrendData: [],
      actPeopleData: "",
      pointsData: {},
      DauData: {
        x: [],
        y: [],
      },
    };
  },
  created() {},
  mounted() {
    gloabalCount("", 73, 1);
    this.getPeopleTrend();
    this.getDau();
    this.getActPeople();
    this.getPoints();
  },
  computed: {
    ...mapState(["userId", "tenantId", "houseId", "communityId"]),
  },
  destroyed() {},
  methods: {
    async getPoints() {
      let _this = this;
      let params = {
        tenantId: _this.tenantId,
      };
      let res = await this.$axios.get(`${getPointsUrl}`, { params: params });
      if (res.code == 200) {
        this.pointsData = res.data;
        this.pointsData.x = [];
        this.pointsData.y1 = [];
        this.pointsData.y2 = [];
        if (res.data.pointConsume) {
          res.data.pointConsume.forEach((ele) => {
            let day;
            if (ele.day) {
              day = moment(ele.day).format("MM.DD");
            }
            this.pointsData.x.unshift(day);
            this.pointsData.y1.unshift(ele.total);
          });
        }
        if (res.data.pointProduce) {
          res.data.pointProduce.forEach((ele) => {
            this.pointsData.y2.unshift(ele.total);
          });
        }
        console.log(drawDLineChart, this.pointsData, "++=");
        drawDLineChart(
          "Points-b",
          this.pointsData.x,
          this.pointsData.y1,
          this.pointsData.y2
        );
      }
    },
    async getActPeople() {
      let _this = this;
      let params = {
        tenantId: _this.tenantId,
      };
      let res = await this.$axios.get(`${getActPeopleUrl}`, { params: params });
      if (res.code == 200) {
        this.actPeopleData = res.data;
        let arr = [];
        this.actPeopleData.forEach((ele) => {
          arr.push(ele.total);
        });
        let oMax = Math.max.apply(null, arr);
        this.actPeopleData.forEach((ele) => {
          if (oMax > 0) {
            ele.oWidth = (ele.total / oMax).toFixed(2) * 100 + "%";
          } else {
            ele.oWidth = 0;
          }
        });
      }
    },
    async getDau() {
      let _this = this;
      this.DauData = {
        x: [],
        y: [],
      };
      let params = {
        tenantId: _this.tenantId,
      };
      let res = await this.$axios.get(`${getDauUrl}`, {
        params: params,
      });
      if (res.code == 200) {
        res.data.forEach((ele) => {
          let day;
          if (ele.day) {
            day = moment(ele.day).format("MM.DD");
          }
          this.DauData.x.unshift(day);
          this.DauData.y.unshift(ele.total);
        });
        console.log(this.DauData);
        drawLineChart("Dau", this.DauData.x, this.DauData.y);
      }
    },
    async getPeopleTrend() {
      let _this = this;
      let params = {
        tenantId: _this.tenantId,
      };
      let res = await this.$axios.get(`${getPeopleTrendUrl}`, {
        params: params,
      });
      if (res.code == 200) {
        this.PeopleTrendData = res.data;
        console.log(res.data);
        this.PeopleTrendData.unshift({
          lastMonthCount: _this.beforeMon + "月新增",
          thisMonthCount: _this.currentMon + "月新增",
          total: "总数",
          type: "",
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.digitalCommunity {
  background: #f9f9f9;
  min-height: 100vh;
  padding: 34px 28px;
  box-sizing: border-box;
  .title {
    padding: 24px 0 40px;
    display: flex;
    align-items: center;
    .title-l,
    .title-r {
      font-size: 36px;
      font-weight: 400;
      color: #9fcaff;
      line-height: 50px;
    }
    .title-c {
      height: 40px;
      background: rgba(225, 225, 225, 0.5);
      width: 2px;
      margin: 0 10px;
    }
    .title-r {
      color: rgba(254, 149, 20, 1);
    }
  }
  .commonCss {
    padding: 0 40px 48px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
    border-radius: 16px;
    margin-bottom: 22px;
  }
  .PeopleTrend {
    height: 578px;
    .item:nth-last-child(1) {
      .item-c {
        color: #fe9717;
      }
    }
    .item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      .item-c {
        width: 25%;
        font-size: 32px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 44px;
        text-align: center;
        &:nth-child(1) {
          font-size: 28px;
          color: rgba(0, 0, 0, 0.25);
        }
      }
    }
    .item:nth-child(2) {
      .item-c {
        font-size: 28px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.25);
        line-height: 40px;
      }
    }
  }
  .Dau {
    height: 630px;
    #Dau {
      height: 500px;
    }
  }
  .ActPeople {
    .item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;
      .item-l,
      .item-r {
        display: flex;
        align-items: center;
        flex: 1;
        font-size: 32px;
        color: rgba(16, 8, 8, 0.25);
      }
      .item-l {
        .num {
          margin-right: 46px;
        }
      }
      .item-r {
        justify-content: right;
      }
      .img {
        width: 150px;
        height: 16px;
        margin-left: 18px;
        .line {
          height: 100%;
          background: #9fcaff;
          border-radius: 4px;
        }
      }
      &:nth-child(1) {
        .item-l,
        .item-r {
          font-weight: 700;
          color: rgba(0, 0, 0, 0.5);
          .img {
            .line {
              background: #fe9514;
            }
          }
        }
      }
      &:nth-child(2) {
        .item-l,
        .item-r {
          font-weight: 700;
          color: rgba(0, 0, 0, 0.5);
          .img {
            .line {
              background: #fe9514;
            }
          }
        }
      }
      &:nth-child(3) {
        .item-l,
        .item-r {
          font-weight: 700;
          color: rgba(0, 0, 0, 0.5);
          .img {
            .line {
              background: #fe9514;
            }
          }
        }
      }
      &:nth-last-child(1) {
        margin: 0;
      }
    }
  }
  .ActPeopleElse {
    text-align: center;
    color: rgba(0, 0, 0, 0.25);
  }
  .Points {
    .Points-t {
      margin-bottom: 26px;
      display: flex;
      padding: 0 30px;
      justify-content: space-between;
      align-items: center;
      .Points-tl,
      .Points-tr {
        width: 250px;
        height: 94px;
        background: rgba(255, 251, 245, 0.9);
        border-radius: 8px;
        text-align: center;
        padding-top: 10px;
        box-sizing: border-box;
        font-size: 28px;
        font-weight: 400;
        color: #fe9514;
        line-height: 36px;
        h3 {
          margin-bottom: 6px;
          white-space: nowrap;
        }
      }
      .Points-tr {
        background: #f9fcff;
        font-size: 28px;
        font-weight: 400;
        color: #9fcaff;
        line-height: 36px;
      }
      .Points-tc {
        width: 2px;
        height: 68px;
        background: rgba(225, 225, 225, 0.5);
      }
    }
    #Points-b {
      height: 370px;
    }
  }
}
</style>
